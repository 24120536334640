<template>
  <div>
    <div class="cotainer-standar text-center">
      <div class="dk-container">
        <span
          class="d-block font-30 font-res-16 mt-res-30 text-white font-weight-6 mb-40  pl-res-5 pr-res-5 "
        >
          DATA BANTUAN SOSIAL PROVINSI PAPUA BARAT
          <br />TAHUN {{ new Date().getFullYear() }}
        </span>
        <div class="container-standar mt-10 mb-10 pb-10">
          <div class="row">
            <div class="col-md-12 mb-10">
              <div class="card shadow">
                <div class="row">
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-data.svg"
                          alt
                        />
                      </div>
                      <h4
                        class="  font-20 text-center font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Jiwa
                        <br />
                        <strong>{{ total_jiwa | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-laki.svg"
                          alt
                        />
                      </div>
                      <h4
                        class=" text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Laki Laki
                        <br />
                        <strong>{{ total_pria | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-perempuan.svg"
                          alt
                        />
                      </div>
                      <h4
                        class="  text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total Perempuan
                        <br />
                        <strong>{{ total_perempuan | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                  <div
                    class="col-md-3 d-inline-flex pad-10 text-center margin-auto"
                  >
                    <div class="pad-20 w-full pos-respossive">
                      <div class="w-100 margin-auto text-center">
                        <img
                          class="margin-auto width-75  text-center "
                          src="@/assets/images/icon/icon-jiwa.svg"
                          alt
                        />
                      </div>
                      <h4
                        class=" text-center font-20 font-semibold mt-4 ml-3 text-primary"
                      >
                        Total KK
                        <br />
                        <strong>{{ total_kk | convertToRupiah }}</strong>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 d-inline-flex">
              <div class="shadow card pad-12 w-100">
                <span
                  class="font-18 text-primary text-center pl-4 mb-10 font-weight-6"
                >
                  <strong>PENDUDUK SESUAI USIA </strong>
                </span>
                <span class="flex justify-center    w-100">
                  <selecYears class="width-50 w-res-80  "></selecYears>
                </span>
                <bar-detail></bar-detail>
              </div>
            </div>
            <div class="col-md-8 d-inline-flex">
              <div class="card w-full shadow">
                <span
                  class="font-18  font-res-16 pb-res-10 text-primary text-center mt-10 pl-4 mb-10 font-weight-6"
                >
                  <strong>
                    PENDUDUK PAPUA BARAT
                    <br />
                    OAP/Non-OAP
                  </strong>
                </span>
                <span class="flex justify-center    w-100">
                  <selecYears class="width-30  w-res-80   "></selecYears>
                </span>
                <!-- <donutchart></donutchart> -->
                <grapichpenduduk class="w-full"></grapichpenduduk>
              </div>
            </div>
          </div>
        </div>
        <div class="container-standar mt-0 mb-10">
          <div class="row">
            <div class="col-lg-12 inline-flex mt-6">
              <div class="card width-100 shadow pad-20">
                <b-card no-body>
                  <b-card-text>
                    <div class="width-95 mt-10">
                      <span
                        class="font-24 text-primary text-center pl-4 mb-10 d-inline-block w-full"
                      >
                        <strong
                          >Program Bantuan Sosial Tahun
                          {{ new Date().getFullYear() }}</strong
                        >
                      </span>
                      <bansos></bansos>
                    </div>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import grapichpenduduk from "../kependudukan/components/grapichpenduduk";
import BarDetail from "../kependudukan/components/BarDetail";
import bansos from "../kependudukan/components/bansos";
import selecYears from "../kependudukan/components/select-years";

export default {
  components: {
    grapichpenduduk,
    BarDetail,
    bansos,
    selecYears,
  },
  name: "data-bantuan-sosial",
  data() {
    return {
      selected1: null,
      options1: [
        { value: null, text: "Tahun 2020" },
        { value: "a", text: "Tahun 2019" },
      ],
      datapendudukMap: [],
      dataOAP: [],
      total_pria: 0,
      total_perempuan: 0,
      total_kk: 0,
      total_jiwa: 0,
      partisipasi_sekolah: {},
      data_provinsi: [],
      data_district: [],
      tabactive_1: true,
      tabactive_2: false,
      tabactive_3: false,
      tabactive_4: false,
      tabactive_5: false,
      tabactive_6: false,
      tabactive_7: false,
      tabactive_8: false,
      tabactive_9: false,
      tabactive_10: false,
      tabactive_11: false,
      tabactive_12: false,
      tabactive_13: false,
      tabactive_14: false,
    };
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    this.getTotalJenisPenduduk();
    this.getDataPartisipasiSekolah();
    this.persebaranOAP();
    this.getDataProvinsi();
  },
  methods: {
    getTotalJenisPenduduk() {
      this.$axios.get("/v1/sensus/jiwa-kk-pria-wanita").then((response) => {
        let result = response.data;
        this.total_pria = result.pria.semua;
        this.total_perempuan = result.wanita.semua;
        this.total_jiwa = result.jiwa.semua;
        this.total_kk = result.kartu_keluarga.semua;
      });
    },
    getDataPartisipasiSekolah() {
      this.$axios.get("/v1/sensus/partisipasi-sekolah").then((response) => {
        let result = response.data;
        this.partisipasi_sekolah = result;
      });
    },
    persebaranOAP() {
      this.$axios.get("/v1/sensus/persebaran-oap").then((response) => {
        this.dataOAP = response.data;
      });
    },
    getDataProvinsi() {
      this.$axios.get("/v1/sensus/kependudukan-provinsi").then((response) => {
        let result = response.data;
        this.data_provinsi = result;
        this.datapendudukMap = result;
      });
    },
    showModalDetail(district_id) {
      this.$axios
        .get("/v1/sensus/city/kependudukan/" + district_id)
        .then((response) => {
          let result = response.data;
          this.data_district = result;
          this.$bvModal.show("modal_detail");
        });
    },
    routerKabuaten(id) {
      this.$router.push("/data-kabupaten/" + id);
      // setTimeout(() => {
      //   window.location.reload()
      // }, 500);
    },
    routerDesa(id, name) {
      this.$router.push("/data-desa/" + id);
      //   setTimeout(() => {
      //   window.location.reload()
      // }, 500);
      this.$store.commit("DISTRICT_STATE", name);
    },
  },
};
</script>
